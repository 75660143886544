import React, { useState, useEffect } from "react";
import { useNavigate, Link } from 'react-router-dom';
import TaskService from "../services/task.service";
import AuthService from "../services/auth.service";
import FadeIn from 'react-fade-in';
import Avatar from "react-avatar";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const TasksList = props => {
    let navigate = useNavigate();
    const currentUser = AuthService.getCurrentUser();
    const [tasks, setTasks] = useState([]);
    const [updates, setUpdates] = useState([]);
    const [update, setUpdate] = useState("");
    const [change, setChange] = useState(false);
    const [currentTask, setCurrentTask] = useState(null);
    const [currentIndex, setCurrentIndex] = useState(-1);
    const [showComplete, setShowComplete] = useState(false);
    const [tasksCount, setTasksCount] = useState(0);
    const [count, setCount] = useState([]);
    const [loading, setLoading] = useState(false);
    const [modalLoading, setModalLoading] = useState(false);
    const bucketURL = "https://storage.googleapis.com/download/storage/v1/b/honee-do-profile/o/";
    const today = new Date();

    const [showUpdates, setShowUpdates] = useState(false);
    const handleUpdatesOpen = () => setShowUpdates(true);
    const handleUpdatesClose = () => setShowUpdates(false);
    const [showMarkComplete, setMarkComplete] = useState(false);
    const handleMarkCompleteOpen = () => setMarkComplete(true);
    const handleMarkCompleteClose = () => setMarkComplete(false);
    const [showDeleteAllCompleted, setDeleteAllCompleted] = useState(false);
    const handleDeleteAllCompletedOpen = () => setDeleteAllCompleted(true);
    const handleDeleteAllCompletedClose = () => setDeleteAllCompleted(false);
    const [showSendReminder, setSendReminder] = useState(false);
    const handleSendReminderOpen = () => setSendReminder(true);
    const handleSendReminderClose = () => setSendReminder(false);
    const [showDeleteTask, setDeleteTask] = useState(false);
    const handleDeleteTaskOpen = () => setDeleteTask(true);
    const handleDeleteTaskClose = () => setDeleteTask(false);

    useEffect(() => {
        if (!currentUser) {
            navigate("/login")
        } else {
            AuthService.refresh(currentUser.userId);
            if (currentUser.familyId === null) {
                navigate("/needfamily")
            }
        }
        getTaskCount();
        if (showComplete) {
            retrieveAll();
        } else {
            retrieveIncomplete();
        }
    }, []);

    function formatTime(string) {
        var options = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(string).toLocaleTimeString([], options);
    }

    const retrieveAll = () => {
        TaskService.getAll()
            .then(response => {
                setTasks(response.data);
                setTasksCount(response.data);

                console.log(response.data);
            })
            .catch(e => {
                console.log(e);
            });
    };

    const retrieveIncomplete = () => {
        setLoading(true);
        TaskService.getIncomplete()
            .then(response => {
                setTasks(response.data);
                console.log(response.data);
                setLoading(false);
            })
            .catch(e => {
                console.log(e);
                setLoading(false);
            });
    };

    const getTaskCount = () => {
        TaskService.getTasksCount()
            .then(response => {
                setCount(response.data)
                console.log(response.data)
            })
            .catch(e => {
                console.log(e);
            });
    };

    const taskCount = count.map(
        (element) => {
            return (
                <div>
                    <div>{element.task_count}/40</div>
                </div>
            )
        }
    );

    const setSwitch = () => {
        if (showComplete === true) {
            setShowComplete(false);
            retrieveIncomplete()
        } else {
            setShowComplete(true);
            retrieveAll()
        }
    };

    const setActiveTask = (task, index) => {
        if (currentIndex === index) {
            setCurrentTask(null)
            setCurrentIndex(-1)
        } else {
            setCurrentTask(task);
            setCurrentIndex(index);
        }
    };

    const getAllUpdates = () => {
        handleUpdatesOpen();
        TaskService.getAllUpdates(currentTask.taskId)
            .then(response => {
                setUpdates(response.data)
                console.log(response.data);
            })
            .catch(e => {
                console.log(e);
            });
    };

    const deleteTask = () => {
        handleDeleteTaskClose();
        TaskService.remove(currentTask.taskId)
            .then(response => {
                console.log(response.data);
                refreshList();
            })
            .catch(e => {
                console.log(e);
            });
    };

    const deleteAllCompleted = () => {
        handleDeleteAllCompletedClose();
        setLoading(true);
        TaskService.removeAllCompleted(currentUser.familyId)
            .then(response => {
                console.log(response.data);
                refreshList();
                setLoading(false);
            })
            .catch(e => {
                console.log(e);
            });
    };

    const markCompleted = () => {
        setModalLoading(true);
        TaskService.markComplete(currentTask.taskId)
            .then(response => {
                //setCurrentTask(initialTaskState);
                handleMarkCompleteClose();
                setModalLoading(false);
                console.log(response.data);
                refreshList();
            })
            .catch(e => {
                console.log(e);
            });
    };

    const refreshList = () => {
        retrieveIncomplete();
        setCurrentTask(null);
        setCurrentIndex(-1);
    };

    const sendReminder = () => {
        handleSendReminderClose();
        TaskService.sendReminder(currentTask.taskId)
            .then(response => {
                console.log(response.data);
            })
            .catch(e => {
                console.log(e);
            });
    };

    const onNewUpdate = (e) => {
        const update = e.target.value;
        setUpdate(update);
        setChange(true);
    };

    const cancelUpdate = () => {
        handleUpdatesClose();
        setUpdate("");
    };

    const sendUpdate = () => {
        setLoading(true);
        let data = {
            name: currentUser.firstname,
            userId: currentTask.userId,
            ownerId: currentTask.ownerId,
            taskUpdate: update
        }
        if (currentUser.userId === currentTask.userId) {
            TaskService.sendUserUpdate(currentTask.taskId, data)
                .then(response => {
                    setUpdate("");
                    handleUpdatesClose();
                    refreshList();
                })
                .catch(e => {
                    console.log(e);
                });
        } else {
            TaskService.sendOwnerUpdate(currentTask.taskId, data)
                .then(response => {
                    setUpdate("");
                    refreshList();
                })
                .catch(e => {
                    console.log(e);
                });
        }
    };

    return (
        <div className="container-flex">
            <div className="list row">
                <div className="col-4">
                    <FadeIn>
                        <h4>Tasks List</h4>
                    </FadeIn>
                </div>
                <div className="col-1 mt-1">
                    <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault"
                            onClick={() => setSwitch()}
                        />
                    </div>
                </div>
                <div className="col mt-2 ms-1" style={{"fontSize": "11px"}}>
                    Show Done Tasks
                </div>
                <div className="col-2 mt-1">
                    {taskCount === 40 ?
                        <div style={{
                            "color": 'red'
                        }}>40/40</div>
                        :
                        <div>{taskCount}</div>
                    }
                </div>
            </div>
            <div className="container-flex mb-5"
                style={{
                    "overflow-x": 'scroll'
                }}
            >
                {loading ?
                    <div>
                        <span className="visually-hidden">Loading...</span>
                    </div>
                    :
                    <div>
                        <FadeIn>
                            {tasks && tasks.length ?
                                <div className="accordion" id="accordionTask" style={{
                                    "font-size": '14.2px'
                                }}>
                                    {tasks &&
                                        tasks.map((task, index) => (
                                            <div key={"a" + index} className="accordion-item">
                                                <h2 className="accordion-header" id={"a" + index}>
                                                    <button
                                                        className={"accordion-button" + (index === currentIndex ? "" : " collapsed")}
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target={"a" + index}
                                                        aria-expanded={(index === currentIndex ? "true" : "false")}
                                                        aria-controls={"a" + index}
                                                        onClick={() => setActiveTask(task, index)}
                                                        style={{
                                                            '--bs-accordion-btn-padding-y': '0.5rem',
                                                            '--bs-accordion-btn-padding-x': '0.5rem'
                                                        }}
                                                    >
                                                        <div className="container">
                                                            <div className="row">
                                                                <div className="col-1 align-middle">
                                                                    {task.ownerMediaLink ?
                                                                        <img className={"img border " + (task.ownerId === currentUser.userId ? "border-primary" : "border-secondary") + " border-3 border-opacity-75 mt-2"} src={bucketURL + task.ownerMediaLink} alt={bucketURL + task.ownerMediaLink} height="40px" />
                                                                        :
                                                                        <Avatar className="mt-2" name={(task.ownerFirst != null ? task.ownerFirst + " " + task.ownerLast : "?")} color="gray" round={true} size="40" textSizeRatio={1.75} />
                                                                    }
                                                                </div>
                                                                <div className="col ms-4">
                                                                    <div className="row justify-content-end mt-1" style={{
                                                                        "font-size": "13px",
                                                                        "max-width": "300px",
                                                                        "--bs-gutter-x": "-0.625rem"
                                                                    }}>
                                                                        <div className="col">
                                                                            <div className="badge bg-light" style={{
                                                                                "color": (task.taskUpdate == null ? "white" : "blue")
                                                                            }}>
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill={(task.taskUpdate == null ? "white" : "blue")} class="bi bi-chat-left-text" viewBox="0 0 16 16">
                                                                                    <path d="M14 1a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H4.414A2 2 0 0 0 3 11.586l-2 2V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12.793a.5.5 0 0 0 .854.353l2.853-2.853A1 1 0 0 1 4.414 12H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                                                                                    <path d="M3 3.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM3 6a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9A.5.5 0 0 1 3 6zm0 2.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5z" />
                                                                                </svg>
                                                                                {" Updated"}
                                                                            </div>
                                                                        </div>
                                                                        <div className="col">
                                                                            <div className="badge bg-light" style={{
                                                                                "color": (task.edited === 0 ? "white" : "goldenrod")
                                                                            }}>
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-pencil " viewBox="0 0 16 16">
                                                                                    <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                                                                                </svg>
                                                                                {" Edited"}
                                                                            </div>
                                                                        </div>
                                                                        <div className="col">
                                                                            {task.dueDate != null &&
                                                                                <div className="badge bg-light" style={{
                                                                                    "color": (task.dueDate > today.toISOString() ? "white" : "red")
                                                                                }}>
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-calendar-check" viewBox="0 0 16 16">
                                                                                        <path d="M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0z" />
                                                                                        <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z" />
                                                                                    </svg>
                                                                                    {" Overdue"}
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                        <div className="col">
                                                                            <div className="badge bg-light" style={{
                                                                                "color": (task.completed === 0 ? "white" : "green")
                                                                            }}>
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-check-circle" viewBox="0 0 16 16">
                                                                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                                                                    <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                                                                                </svg>
                                                                                {" Done"}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="text-truncate mb-1" style={{ 'max-width': '265px' }}>
                                                                            <strong>{task.title}</strong>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col mt-1 mb-1" style={{
                                                                            "color": "#625D5D",
                                                                            "font-size": "13px"
                                                                        }}>
                                                                            {task.dueDate !== null &&
                                                                                <strong>Due Date: {task.dueDate.split("T")[0]}</strong>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </button>
                                                </h2>
                                                <div id={"a" + currentIndex}
                                                    className={"accordion-collapse collapse" + (index === currentIndex ? " show" : "")}
                                                    aria-labelledby={"a" + currentIndex} data-bs-parent={"a" + currentIndex}>
                                                    <div className="accordion-body border">
                                                        <div>
                                                            <div className="mt-1">
                                                                <strong>Title:</strong>
                                                            </div>{" "}
                                                            <div className="text-break">
                                                                <div style={{
                                                                    "white-space": 'pre-wrap'
                                                                }}
                                                                >
                                                                    {task.title}
                                                                </div>
                                                            </div>
                                                            {task.taskUpdate == null ?
                                                                null
                                                                :
                                                                <div>
                                                                    <div style={{ "font-size": '13px' }}>
                                                                        <strong>Latest Update</strong> - {formatTime(task.createdAt)}
                                                                    </div>
                                                                    <div className="alert alert-success text-break"
                                                                        style={{
                                                                            "white-space": 'pre-wrap'
                                                                        }}
                                                                    >
                                                                        "{task.taskUpdate}"
                                                                        <div className="align-right" style={{ "font-size": '13px' }}>-From <strong>{task.authorFirst} {task.authorLast}</strong></div>
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                                                        <div>
                                                            <div className="container mt-1">
                                                                <div className="row row-cols-2" style={{
                                                                    "font-size": '11px'
                                                                }}>
                                                                    <div className="col">
                                                                        <div className="mb-1"><strong>Opened By:</strong></div>
                                                                    </div>
                                                                    <div className="col">
                                                                        <div className="mb-1"><strong>Assigned to:</strong></div>
                                                                    </div>
                                                                </div>
                                                                <div className="row row-cols-4 mb-2" style={{
                                                                    "font-size": '11px'
                                                                }}>
                                                                    <div className="col-1">
                                                                        {task.userMediaLink ?
                                                                            <img className="img" src={bucketURL + task.userMediaLink} alt={bucketURL + task.userMediaLink} height="25px" />
                                                                            :
                                                                            <Avatar name={(task.firstname != null ? task.firstname + " " + task.lastname : "?")} color="gray" round={true} size="25" textSizeRatio={1.75} />
                                                                        }
                                                                    </div>
                                                                    <div className="col-5">
                                                                        {task.userId != null ?
                                                                            <div className="ms-2 mt-1">{task.firstname} {task.lastname}</div>
                                                                            :
                                                                            <div className="mt-1 ms-2" style={{
                                                                                "color": 'red'
                                                                            }}>Nobody!</div>
                                                                        }
                                                                    </div>
                                                                    <div className="col-1">
                                                                        {task.ownerMediaLink ?
                                                                            <img className="img"
                                                                                src={bucketURL + task.ownerMediaLink}
                                                                                alt={bucketURL + task.ownerMediaLink}
                                                                                height="25px" />
                                                                            :
                                                                            <Avatar
                                                                                name={(task.ownerFirst != null ? task.ownerFirst + " " + task.ownerLast : "?")}
                                                                                color="gray"
                                                                                round={true} size="25"
                                                                                textSizeRatio={1.75} />
                                                                        }
                                                                    </div>
                                                                    {task.ownerId != null ?
                                                                        <div className="col-5">
                                                                            <div className="ms-2 mt-1">{task.ownerFirst} {task.ownerLast}</div>
                                                                        </div>
                                                                        :
                                                                        <div className="mt-1 ms-2" style={{
                                                                            "color": 'red'
                                                                        }}>Nobody!</div>
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className="mt-1">
                                                                <strong>Description:</strong>
                                                            </div>{" "}
                                                            <div className="text-break">
                                                                <div style={{
                                                                    "white-space": 'pre-wrap'
                                                                }}
                                                                >
                                                                    {task.description}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="border-top border-secondary mt-2" style={{
                                                            "color":
                                                                (task.dueDate < today.toISOString() ?
                                                                    'red'
                                                                    :
                                                                    ""
                                                                )
                                                        }}>
                                                            <div className="row">
                                                                <div className="col">
                                                                    <div className="mt-1">
                                                                        {task.dueDate !== null &&
                                                                            <strong>Due Date: {task.dueDate.split("T")[0]}</strong>
                                                                        }
                                                                    </div>
                                                                    <div className="">

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <p />
                                                        <div className="container-fluid" style={{
                                                            "justify-content": 'center'
                                                        }}>
                                                            <div style={{ "width": "100%" }} classname="row">
                                                                <ul className="d-flex justify-content-center" style={{
                                                                    "font-size": '12px',
                                                                    "padding-inline-start": "0px"
                                                                }}>
                                                                    {currentUser.roles[0] === "ROLE_ADMIN" ?
                                                                        <div className="dropdown">
                                                                            <button className="btn btn-light" type="button" data-bs-toggle="dropdown" aria-expanded="false"
                                                                                style={{
                                                                                    "--bs-btn-line-height": "1.3",
                                                                                    "--bs-btn-border-radius": "4rem",
                                                                                    "--bs-btn-padding-x": "0.95rem"
                                                                                }}>
                                                                                <div>
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-calendar-check-fill" viewBox="0 0 16 16">
                                                                                        <path d="M4 .5a.5.5 0 0 0-1 0V1H2a2 2 0 0 0-2 2v1h16V3a2 2 0 0 0-2-2h-1V.5a.5.5 0 0 0-1 0V1H4V.5zM16 14V5H0v9a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2zm-5.146-5.146-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L7.5 10.793l2.646-2.647a.5.5 0 0 1 .708.708z" />
                                                                                    </svg>
                                                                                    <div style={{
                                                                                        "fontSize": "10px"
                                                                                    }}>
                                                                                        <strong></strong>
                                                                                    </div>
                                                                                </div>
                                                                            </button>
                                                                            <ul class="dropdown-menu">
                                                                                {task.dueDate !== null &&
                                                                                    <add-to-calendar-button
                                                                                        name={task.title}
                                                                                        description={task.description}
                                                                                        options="'Apple','Google','Microsoft365'"
                                                                                        //location="World Wide Web"
                                                                                        startDate={task.dueDate.split("T")[0]}
                                                                                        //timeZone="America/Los_Angeles"
                                                                                        buttonStyle="3d"
                                                                                        size="3"
                                                                                        styleLight="--btn-shadow: rgba(0 0 0) 0px 3px 5px -1px;"
                                                                                        lightMode="bodyScheme"
                                                                                        hideTextLabelButton
                                                                                        buttonsList
                                                                                    >
                                                                                    </add-to-calendar-button>
                                                                                }
                                                                            </ul>
                                                                            <button className="btn btn-warning ms-2"
                                                                                onClick={handleSendReminderOpen}
                                                                                style={{
                                                                                    "--bs-btn-line-height": "1.3",
                                                                                    "--bs-btn-border-radius": "4rem",
                                                                                    "--bs-btn-padding-x": "0.95rem"
                                                                                }}>
                                                                                <div>
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-exclamation-circle" viewBox="0 0 16 16">
                                                                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                                                                        <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
                                                                                    </svg>
                                                                                    <div style={{
                                                                                        "fontSize": "10px"
                                                                                    }}>
                                                                                        <strong></strong>
                                                                                    </div>
                                                                                </div>
                                                                            </button>
                                                                            <button
                                                                                //type="submit"
                                                                                className="btn btn-primary ms-2"
                                                                                style={{
                                                                                    "--bs-btn-line-height": "2",
                                                                                    "--bs-btn-border-radius": "4rem",
                                                                                    "--bs-btn-padding-x": "0.95rem"
                                                                                }}
                                                                            >

                                                                                <div style={{
                                                                                    "fontSize": "10px"
                                                                                }}>
                                                                                    <Link
                                                                                        to={"/tasks/edit/" + task.taskId}
                                                                                        className="nav-link"
                                                                                    >
                                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-pencil" viewBox="0 0 16 16">
                                                                                            <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                                                                                        </svg>
                                                                                    </Link>
                                                                                </div>
                                                                            </button>

                                                                            <button
                                                                                className="btn btn-secondary ms-2"
                                                                                onClick={getAllUpdates}

                                                                                style={{
                                                                                    "--bs-btn-line-height": "1.3",
                                                                                    "--bs-btn-border-radius": "4rem",
                                                                                    "--bs-btn-padding-x": "0.95rem"
                                                                                }}
                                                                            >
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-chat-dots" viewBox="0 0 16 16">
                                                                                    <path d="M5 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                                                                                    <path d="m2.165 15.803.02-.004c1.83-.363 2.948-.842 3.468-1.105A9.06 9.06 0 0 0 8 15c4.418 0 8-3.134 8-7s-3.582-7-8-7-8 3.134-8 7c0 1.76.743 3.37 1.97 4.6a10.437 10.437 0 0 1-.524 2.318l-.003.011a10.722 10.722 0 0 1-.244.637c-.079.186.074.394.273.362a21.673 21.673 0 0 0 .693-.125zm.8-3.108a1 1 0 0 0-.287-.801C1.618 10.83 1 9.468 1 8c0-3.192 3.004-6 7-6s7 2.808 7 6c0 3.193-3.004 6-7 6a8.06 8.06 0 0 1-2.088-.272 1 1 0 0 0-.711.074c-.387.196-1.24.57-2.634.893a10.97 10.97 0 0 0 .398-2z" />
                                                                                </svg>
                                                                                <div style={{
                                                                                    "fontSize": "10px"
                                                                                }}>
                                                                                    <strong></strong>
                                                                                </div>
                                                                            </button>
                                                                            {task.completed === 0 ?
                                                                                <button
                                                                                    className="btn btn-success ms-2"
                                                                                    onClick={handleMarkCompleteOpen}
                                                                                    style={{
                                                                                        "--bs-btn-line-height": "1.3",
                                                                                        "--bs-btn-border-radius": "4rem",
                                                                                        "--bs-btn-padding-x": "0.95rem"
                                                                                    }}
                                                                                >
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-check-square" viewBox="0 0 16 16">
                                                                                        <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                                                                                        <path d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.235.235 0 0 1 .02-.022z" />
                                                                                    </svg>
                                                                                    <div style={{
                                                                                        "fontSize": "10px"
                                                                                    }}>
                                                                                        <strong></strong>
                                                                                    </div>
                                                                                </button>
                                                                                :
                                                                                <button
                                                                                    className="btn btn-danger ms-2"
                                                                                    onClick={handleDeleteTaskOpen}
                                                                                    style={{
                                                                                        "--bs-btn-line-height": "1.3",
                                                                                        "--bs-btn-border-radius": "4rem",
                                                                                        "--bs-btn-padding-x": "0.95rem"
                                                                                    }}
                                                                                >
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-trash3" viewBox="0 0 16 16">
                                                                                        <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                                                                                    </svg>
                                                                                    <div style={{
                                                                                        "fontSize": "10px"
                                                                                    }}>
                                                                                        <strong>Delete</strong>
                                                                                    </div>
                                                                                </button>
                                                                            }

                                                                        </div>
                                                                        :
                                                                        <div>
                                                                            {task.userId === currentUser.userId ?
                                                                                <div className="dropdown">
                                                                                    <button className="btn btn-light" type="button" data-bs-toggle="dropdown" aria-expanded="false"
                                                                                        style={{
                                                                                            "--bs-btn-line-height": "1.3",
                                                                                            "--bs-btn-border-radius": "4rem",
                                                                                            "--bs-btn-padding-x": "0.95rem"
                                                                                        }}>
                                                                                        <div>
                                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-calendar-check-fill" viewBox="0 0 16 16">
                                                                                                <path d="M4 .5a.5.5 0 0 0-1 0V1H2a2 2 0 0 0-2 2v1h16V3a2 2 0 0 0-2-2h-1V.5a.5.5 0 0 0-1 0V1H4V.5zM16 14V5H0v9a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2zm-5.146-5.146-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L7.5 10.793l2.646-2.647a.5.5 0 0 1 .708.708z" />
                                                                                            </svg>
                                                                                            <div style={{
                                                                                                "fontSize": "10px"
                                                                                            }}>
                                                                                                <strong></strong>
                                                                                            </div>
                                                                                        </div>
                                                                                    </button>
                                                                                    <ul class="dropdown-menu">
                                                                                        {task.dueDate !== null &&
                                                                                            <add-to-calendar-button
                                                                                                name={task.title}
                                                                                                description={task.description}
                                                                                                options="'Apple','Google','Microsoft365'"
                                                                                                //location="World Wide Web"
                                                                                                startDate={task.dueDate.split("T")[0]}
                                                                                                //timeZone="America/Los_Angeles"
                                                                                                buttonStyle="3d"
                                                                                                size="3"
                                                                                                styleLight="--btn-shadow: rgba(0 0 0) 0px 3px 5px -1px;"
                                                                                                lightMode="bodyScheme"
                                                                                                hideTextLabelButton
                                                                                                buttonsList
                                                                                            >
                                                                                            </add-to-calendar-button>
                                                                                        }
                                                                                    </ul>
                                                                                    <button className="btn btn-warning ms-2"
                                                                                        onClick={handleSendReminderOpen}
                                                                                        style={{
                                                                                            "--bs-btn-line-height": "1.3",
                                                                                            "--bs-btn-border-radius": "4rem",
                                                                                            "--bs-btn-padding-x": "0.95rem"
                                                                                        }}>
                                                                                        <div>
                                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-exclamation-circle" viewBox="0 0 16 16">
                                                                                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                                                                                <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
                                                                                            </svg>
                                                                                            <div style={{
                                                                                                "fontSize": "10px"
                                                                                            }}>
                                                                                                <strong></strong>
                                                                                            </div>
                                                                                        </div>
                                                                                    </button>
                                                                                    <button
                                                                                        //type="submit"
                                                                                        className="btn btn-primary ms-2"
                                                                                        style={{
                                                                                            "--bs-btn-line-height": "2",
                                                                                            "--bs-btn-border-radius": "4rem",
                                                                                            "--bs-btn-padding-x": "0.95rem"
                                                                                        }}
                                                                                    >

                                                                                        <div style={{
                                                                                            "fontSize": "10px"
                                                                                        }}>
                                                                                            <Link
                                                                                                to={"/tasks/edit/" + task.taskId}
                                                                                                className="nav-link"
                                                                                            >
                                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-pencil" viewBox="0 0 16 16">
                                                                                                    <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                                                                                                </svg>
                                                                                            </Link>
                                                                                        </div>
                                                                                    </button>

                                                                                    <button
                                                                                        className="btn btn-secondary ms-2"
                                                                                        onClick={getAllUpdates}
                                                                                        style={{
                                                                                            "--bs-btn-line-height": "1.3",
                                                                                            "--bs-btn-border-radius": "4rem",
                                                                                            "--bs-btn-padding-x": "0.95rem"
                                                                                        }}
                                                                                    >
                                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-chat-dots" viewBox="0 0 16 16">
                                                                                            <path d="M5 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                                                                                            <path d="m2.165 15.803.02-.004c1.83-.363 2.948-.842 3.468-1.105A9.06 9.06 0 0 0 8 15c4.418 0 8-3.134 8-7s-3.582-7-8-7-8 3.134-8 7c0 1.76.743 3.37 1.97 4.6a10.437 10.437 0 0 1-.524 2.318l-.003.011a10.722 10.722 0 0 1-.244.637c-.079.186.074.394.273.362a21.673 21.673 0 0 0 .693-.125zm.8-3.108a1 1 0 0 0-.287-.801C1.618 10.83 1 9.468 1 8c0-3.192 3.004-6 7-6s7 2.808 7 6c0 3.193-3.004 6-7 6a8.06 8.06 0 0 1-2.088-.272 1 1 0 0 0-.711.074c-.387.196-1.24.57-2.634.893a10.97 10.97 0 0 0 .398-2z" />
                                                                                        </svg>
                                                                                        <div style={{
                                                                                            "fontSize": "10px"
                                                                                        }}>
                                                                                            <strong></strong>
                                                                                        </div>
                                                                                    </button>
                                                                                    {task.completed === 0 ?
                                                                                        <button
                                                                                            className="btn btn-success ms-2"
                                                                                            onClick={handleMarkCompleteOpen}
                                                                                            style={{
                                                                                                "--bs-btn-line-height": "1.3",
                                                                                                "--bs-btn-border-radius": "4rem",
                                                                                                "--bs-btn-padding-x": "0.95rem"
                                                                                            }}
                                                                                        >
                                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-check-square" viewBox="0 0 16 16">
                                                                                                <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                                                                                                <path d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.235.235 0 0 1 .02-.022z" />
                                                                                            </svg>
                                                                                            <div style={{
                                                                                                "fontSize": "10px"
                                                                                            }}>
                                                                                                <strong></strong>
                                                                                            </div>
                                                                                        </button>
                                                                                        :
                                                                                        <button
                                                                                            className="btn btn-danger ms-2"
                                                                                            onClick={handleDeleteTaskOpen}
                                                                                            style={{
                                                                                                "--bs-btn-line-height": "1.3",
                                                                                                "--bs-btn-border-radius": "4rem",
                                                                                                "--bs-btn-padding-x": "0.95rem"
                                                                                            }}
                                                                                        >
                                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-trash3" viewBox="0 0 16 16">
                                                                                                <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                                                                                            </svg>
                                                                                            <div style={{
                                                                                                "fontSize": "10px"
                                                                                            }}>
                                                                                                <strong>Delete</strong>
                                                                                            </div>
                                                                                        </button>
                                                                                    }

                                                                                </div>
                                                                                :
                                                                                <div>
                                                                                    {task.ownerId === currentUser.userId &&
                                                                                        <div className="dropdown">
                                                                                            <button className="btn btn-light" type="button" data-bs-toggle="dropdown" aria-expanded="false"
                                                                                                style={{
                                                                                                    "--bs-btn-line-height": "1.3",
                                                                                                    "--bs-btn-border-radius": "4rem",
                                                                                                    "--bs-btn-padding-x": "0.95rem"
                                                                                                }}>
                                                                                                <div>
                                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-calendar-check-fill" viewBox="0 0 16 16">
                                                                                                        <path d="M4 .5a.5.5 0 0 0-1 0V1H2a2 2 0 0 0-2 2v1h16V3a2 2 0 0 0-2-2h-1V.5a.5.5 0 0 0-1 0V1H4V.5zM16 14V5H0v9a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2zm-5.146-5.146-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L7.5 10.793l2.646-2.647a.5.5 0 0 1 .708.708z" />
                                                                                                    </svg>
                                                                                                    <div style={{
                                                                                                        "fontSize": "10px"
                                                                                                    }}>
                                                                                                        <strong></strong>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </button>
                                                                                            <ul class="dropdown-menu">
                                                                                                {task.dueDate !== null &&
                                                                                                    <add-to-calendar-button
                                                                                                        name={task.title}
                                                                                                        description={task.description}
                                                                                                        options="'Apple','Google','Microsoft365'"
                                                                                                        //location="World Wide Web"
                                                                                                        startDate={task.dueDate.split("T")[0]}
                                                                                                        //timeZone="America/Los_Angeles"
                                                                                                        buttonStyle="3d"
                                                                                                        size="3"
                                                                                                        styleLight="--btn-shadow: rgba(0 0 0) 0px 3px 5px -1px;"
                                                                                                        lightMode="bodyScheme"
                                                                                                        hideTextLabelButton
                                                                                                        buttonsList
                                                                                                    >
                                                                                                    </add-to-calendar-button>
                                                                                                }
                                                                                            </ul>
                                                                                            <button
                                                                                                className="btn btn-secondary ms-2"
                                                                                                onClick={getAllUpdates}
                                                                                                style={{
                                                                                                    "--bs-btn-line-height": "1.3",
                                                                                                    "--bs-btn-border-radius": "4rem",
                                                                                                    "--bs-btn-padding-x": "0.95rem"
                                                                                                }}
                                                                                            >
                                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-chat-dots" viewBox="0 0 16 16">
                                                                                                    <path d="M5 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                                                                                                    <path d="m2.165 15.803.02-.004c1.83-.363 2.948-.842 3.468-1.105A9.06 9.06 0 0 0 8 15c4.418 0 8-3.134 8-7s-3.582-7-8-7-8 3.134-8 7c0 1.76.743 3.37 1.97 4.6a10.437 10.437 0 0 1-.524 2.318l-.003.011a10.722 10.722 0 0 1-.244.637c-.079.186.074.394.273.362a21.673 21.673 0 0 0 .693-.125zm.8-3.108a1 1 0 0 0-.287-.801C1.618 10.83 1 9.468 1 8c0-3.192 3.004-6 7-6s7 2.808 7 6c0 3.193-3.004 6-7 6a8.06 8.06 0 0 1-2.088-.272 1 1 0 0 0-.711.074c-.387.196-1.24.57-2.634.893a10.97 10.97 0 0 0 .398-2z" />
                                                                                                </svg>
                                                                                                <div style={{
                                                                                                    "fontSize": "10px"
                                                                                                }}>
                                                                                                    <strong></strong>
                                                                                                </div>
                                                                                            </button>
                                                                                            {task.completed === 0 ?
                                                                                                <button
                                                                                                    className="btn btn-success ms-2"
                                                                                                    onClick={handleMarkCompleteOpen}
                                                                                                    style={{
                                                                                                        "--bs-btn-line-height": "1.3",
                                                                                                        "--bs-btn-border-radius": "4rem",
                                                                                                        "--bs-btn-padding-x": "0.95rem"
                                                                                                    }}
                                                                                                >
                                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-check-square" viewBox="0 0 16 16">
                                                                                                        <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                                                                                                        <path d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.235.235 0 0 1 .02-.022z" />
                                                                                                    </svg>
                                                                                                    <div style={{
                                                                                                        "fontSize": "10px"
                                                                                                    }}>
                                                                                                        <strong></strong>
                                                                                                    </div>
                                                                                                </button>
                                                                                                :
                                                                                                <button
                                                                                                    className="btn btn-danger ms-2"
                                                                                                    onClick={handleDeleteTaskOpen}
                                                                                                    style={{
                                                                                                        "--bs-btn-line-height": "1.3",
                                                                                                        "--bs-btn-border-radius": "4rem",
                                                                                                        "--bs-btn-padding-x": "0.95rem"
                                                                                                    }}
                                                                                                >
                                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-trash3" viewBox="0 0 16 16">
                                                                                                        <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                                                                                                    </svg>
                                                                                                    <div style={{
                                                                                                        "fontSize": "10px"
                                                                                                    }}>
                                                                                                        <strong>Delete</strong>
                                                                                                    </div>
                                                                                                </button>
                                                                                            }
                                                                                        </div>
                                                                                    }
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    }
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    <div>
                                        <Modal centered show={showDeleteAllCompleted} onHide={handleDeleteAllCompletedClose}>
                                            <Modal.Header closeButton>
                                                <Modal.Title>Delete All Completed Tasks?</Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>
                                                <span>Are you sure you want to permanently delete all completed tasks?
                                                    This is helpful if you need to make room for more tasks.</span>
                                            </Modal.Body>
                                            <Modal.Footer>
                                                <Button variant="secondary" onClick={handleDeleteAllCompletedClose}>
                                                    Cancel
                                                </Button>
                                                <Button variant="danger" onClick={deleteAllCompleted}>
                                                    Yes, Delete!
                                                </Button>
                                            </Modal.Footer>
                                        </Modal>
                                    </div>
                                </div>
                                :
                                <h4 style={{
                                    "margin-top": "15rem",
                                    "textAlign": "center"
                                }}>
                                    Hit ADD to create a task!
                                </h4>
                            }
                        </FadeIn>
                    </div>
                }
            </div>
            {
                currentTask &&
                <div>
                    <div>
                        <Modal centered show={showDeleteTask} onHide={handleDeleteTaskClose}>
                            <Modal.Header closeButton>
                                <Modal.Title>Delete Task?</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <span>Are you sure you want to delete this task?</span>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleDeleteTaskClose}>
                                    Cancel
                                </Button>
                                <Button variant="danger" onClick={deleteTask}>
                                    Yes, Delete!
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </div>
                    <div>
                        <Modal centered show={showMarkComplete} onHide={handleMarkCompleteClose}>
                            <Modal.Header closeButton>
                                <Modal.Title>Mark Completed?</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                {modalLoading ?
                                    <FadeIn>
                                        <div className="d-flex justify-content-center align-items-center">
                                            <div className="spinner-border text-primary" style={{ "width": "5rem", "height": "5rem" }} role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </div>
                                        </div>
                                    </FadeIn>
                                    :
                                    <span>Do you want to mark this task as completed? "{currentTask.title}"</span>
                                }
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant={modalLoading ? "disabled" : "success"} onClick={markCompleted}>
                                    Mark Complete!
                                </Button>
                                <Button variant="secondary" onClick={handleMarkCompleteClose}>
                                    Close
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </div>
                    <div>
                        <Modal centered show={showSendReminder} onHide={handleSendReminderClose}>
                            <Modal.Header closeButton>
                                <Modal.Title>Send Reminder Notification?</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <span>Do you want to send {currentTask.ownerFirst} a reminder notification?</span>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="warning" onClick={sendReminder}>
                                    Yes, Send!
                                </Button>
                                <Button variant="secondary" onClick={handleSendReminderClose}>
                                    Cancel
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </div>
                    <div>
                        <Modal centered show={showUpdates} onHide={handleUpdatesClose}>
                            <Modal.Header closeButton>
                                <Modal.Title>Updates</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                {loading ?
                                    <FadeIn>
                                        <div className="d-flex justify-content-center align-items-center">
                                            <div className="spinner-border text-primary" style={{ "width": "5rem", "height": "5rem" }} role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </div>
                                        </div>
                                    </FadeIn>
                                    :
                                    <div>
                                        <textarea
                                            className="form-control"
                                            id="message-text"
                                            rows="4"
                                            value={update}
                                            onChange={onNewUpdate}
                                        />
                                        {update.length > 220 ?
                                            <div style={{
                                                "color": 'red'
                                            }}>
                                                {update.length}/220
                                            </div>
                                            :
                                            <div>
                                                {update.length}/220
                                            </div>
                                        }
                                    </div>
                                }
                            </Modal.Body>
                            <Modal.Footer>
                                {!loading &&
                                    <div className="container d-flex justify-content-end">
                                        <Button variant="secondary me-2" onClick={cancelUpdate}>
                                            Cancel
                                        </Button>
                                        {update.length < 1 ?
                                            <button type="button" className="btn btn-success disabled">
                                                Save Update
                                            </button>
                                            :
                                            <button type="button" className={"btn btn-success " + (update.length > 220 && "disabled")}
                                                onClick={sendUpdate}>
                                                Save Update
                                            </button>
                                        }
                                    </div>
                                }
                            </Modal.Footer>
                            {currentTask.taskUpdate != null ?
                                <Modal.Body>
                                    <div>
                                        <label style={{ "font-size": '13px' }}>
                                            <strong>Latest
                                                Update</strong> - {formatTime(currentTask.createdAt)}
                                        </label>
                                        <div
                                            className="alert alert-success text-break"
                                            style={{ "white-space": 'pre-wrap' }}>
                                            "{currentTask.taskUpdate}"
                                            <div className="align-right"
                                                style={{ "font-size": '13px' }}>-From <strong>{currentTask.authorFirst} {currentTask.authorLast}</strong>
                                            </div>
                                        </div>
                                    </div>
                                    {updates &&
                                        updates
                                            .slice()
                                            .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
                                            .map((update, index) => (
                                                <div key={"b" + index}>
                                                    <label style={{ fontSize: '13px' }}>
                                                        {formatTime(update.createdAt)}
                                                    </label>
                                                    <div
                                                        className="alert alert-secondary text-break"
                                                        style={{ whiteSpace: 'pre-wrap' }}
                                                    >
                                                        "{update.oldUpdate}"
                                                        <div className="align-right" style={{ fontSize: '13px' }}>
                                                            - From <strong>{update.firstname} {update.lastname}</strong>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                </Modal.Body>
                                :
                                null
                            }
                        </Modal>
                    </div>
                </div>
            }
        </div >
    );
};

export default TasksList;